import request from '../utils/request'

const Api = {
    MerchantInfo: 'tax/merchant-info',
    MerchantDetail: 'tax/merchant-detail',
    ChangeUserPass: 'user/change-pass',
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getMerchantDetail (data) {
    return request({
        url: Api.MerchantDetail,
        method: 'GET',
        params:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getMerchantInfo (data) {
    return request({
        url: Api.MerchantInfo,
        method: 'GET',
        params:data
    })
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function changePass (data) {
    return request({
        url: Api.ChangeUserPass,
        method: 'POST',
        data:data
    })
}
