import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'login',
            is_show:false,
        },
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('../views/login/login.vue'),
        meta: {
            title: '登录',
            is_show:false,
        },
    },
    {
        name: 'tax_list',
        path: '/tax/list',
        component: () => import('../views/tax_list/index.vue'),
        meta: {
            title: '发票列表',
            is_show:true,
        },
    },
    {
        name: 'tax_add',
        path: '/tax/add',
        component: () => import('../views/tax_add/index.vue'),
        meta: {
            title: '发票开票申请',
            is_show:false,
        },
    },
    {
        name: 'pay_index',
        path: '/pay/index',
        component: () => import('../views/payment/index.vue'),
        meta: {
            title: '税费支付',
            is_show:false,
        },
    },
    {
        name: 'user_index',
        path: '/user/index',
        component: () => import('../views/user/index.vue'),
        meta: {
            title: '账户中心',
            is_show:true,
        },
    },
    {
        name: 'contract_list',
        path: '/contract/list',
        component: () => import('../views/contract_list/index.vue'),
        meta: {
            title: '合同管理',
            is_show:true,
        },
    },
    {
        name: 'contract_detail',
        path: '/contract/detail',
        component: () => import('../views/contract_detail/index.vue'),
        meta: {
            title: '合同详情',
            is_show:false,
        },
    },
    {
        name: 'parking_list',
        path: '/parking_list/list',
        component: () => import('../views/parking_list/index.vue'),
        meta: {
            title: '停车管理',
            is_show:true,
        },
    },
    {
        name: 'parking_detail',
        path: '/parking_detail/detail',
        component: () => import('../views/parking_detail/index.vue'),
        meta: {
            title: '停车详情',
            is_show:false,
        },
    },
    {
        name: 'index',
        path: '/index/index',
        component: () => import('../views/index/index.vue'),
        meta: {
            title: '龙吴商户服务平台',
            is_show:true,
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    const user = localStorage.getItem('user');

    if (title) {
        document.title = title;
    }
    let path = to.path;
    if (!user && path !=='/login') {
        next({
            path:"/login"
        });
    } else if(user && (path === '/' || path === '/login')){
        next({
            path:"/index/index"
        });
    } else {
        next();
    }

});

// eslint-disable-next-line no-unused-vars
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
});

export default router;
