import request from '../utils/request'

const Api = {
    InvoiceList: 'tax/invoice-list',
    InvoiceInfo: 'tax/invoice-info'
}

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getInvoiceList (data) {
    return request({
        url: Api.InvoiceList,
        method: 'GET',
        params:data
    })
}


/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getInvoiceInfo (data) {
    return request({
        url: Api.InvoiceInfo,
        method: 'POST',
        data:data
    })
}