// mutations.js
let mutations = {
    setUserToken(state,token){
        state.userToken = token
    },
    setMerchantInfo(state,info){
        state.merchantInfo = info
    },
    setInvoiceTitleList(state,list){
        state.invoiceTitleList = list
    }
}
export default mutations