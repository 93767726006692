// actions.js
import {getMerchantDetail} from "../api/merchant";
import {getUserToken} from "../api/token";
import {getInvoiceList} from "../api/rise";
let actions = {
    // 获取商户信息
    loadMerchantInfo(context,id){
        getMerchantDetail({id:id}).then((resp) => {
            context.commit('setMerchantInfo', resp.Data);
            localStorage.setItem('user',JSON.stringify(resp.Data))
        }).catch((err) => {
            console.log(err);
        });
    },
    loadInvoiceTitleList(context){
        getInvoiceList({}).then((resp) => {
            context.commit('setInvoiceTitleList', resp.Data);
            localStorage.setItem('list',JSON.stringify(resp.Data))
        }).catch((err) => {
            console.log(err);
        });
    },
    // 刷新token
    refreshToken(context,id) {
        getUserToken({uid:id}).then((resp) => {
            context.commit('setUserToken', resp.Data.token);
            localStorage.setItem('user_token',resp.Data.token)
        }).catch((err) => {
            console.log(err);
        });
    }
}
export default actions