<template>
  <div id="app">
    <router-view />
    <van-tabbar v-model="active" v-show="$route.meta.is_show">
      <van-tabbar-item
          v-for="(item,index) in tabbars"
          :key="index"
          @click="tab(index,item.name)"
      >
        <span :class="currIndex == index ? active:''">{{item.title}}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.normal">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name:"App",
  data(){
    return {
      routeName:'',
      currIndex: 0,
      active: 0,
      tabbars: [
        {
          name: "/index/index",
          title: "首页",
          normal: require("../public/assets/icon/index.png"),
          active: require("../public/assets/icon/index_ac.png")
        },
        {
          name: "/contract/list",
          title: "合同",
          normal: require("../public/assets/icon/contract.png"),
          active: require("../public/assets/icon/contract_ac.png")
        },
        {
          name: "/tax/list",
          title: "发票",
          normal: require("../public/assets/icon/invoice.png"),
          active: require("../public/assets/icon/invoice_ac.png")
        },
        {
          name: "/user/index",
          title: "我的",
          normal: require("../public/assets/icon/mine.png"),
          active: require("../public/assets/icon/mine_ac.png")
        }
      ]
    }
  },
  created() {

  },
  methods: {
    tab(index, val) {
      this.currIndex = index;
      this.$router.push(val);

    }
  },
  watch:{
    $route(){
      switch(this.$route.path){
        case '/index/index'://首页
          this.active=0
          break
        case '/contract/list'://合同
          this.active=1
          break
        case '/tax/list'://发票
          this.active=2
          break
        case '/user/index'://我的
          this.active=3
          break
        default:
          this.active=-1
          break
      }

    }

  },



};
</script>
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.van-tabbar--fixed .van-tabbar-item--active{
  /*color: #e10f02;*/
  color: #ff552e;
}
</style>
