import { createApp } from 'vue'
import 'amfe-flexible';
import App from './App.vue'
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
import './index.css'
//兼容微信
import 'babel-polyfill';
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

const app = createApp(App);
app.use(router);
app.use(Vant);
app.use(store);
app.mount('#app');

