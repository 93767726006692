// getters.js
let getters = {
    getMerchantInfo(state) {
        return state.merchantInfo;
    },
    getUserToken(state) {
        return state.userToken;
    },
    getInvoiceTitleList(state) {
        return state.invoiceTitleList;
    }
}
export default getters