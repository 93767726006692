import axios from "axios";
import {Toast} from "vant";
import {useRouter} from "vue-router";
import {getUserToken} from "../api/token";
const user = JSON.parse(localStorage.getItem('user'));
const router = useRouter();
// 创建axios实例
const service = axios.create({
    // 在请求地址前面加上baseURL
    //baseURL: import.meta.env.VITE_NODE_ENV,
    baseURL: '/api/',
    // 当发送跨域请求时携带cookie
    // withCredentials: true,
    timeout: 10000,
});

// 请求拦截
service.interceptors.request.use(
    (config) => {
        // 模拟指定请求令牌
        config.headers["X-Token"] = localStorage.getItem('user_token');
        return config;
    },
    (error) => {
        // 请求错误的统一处理
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    /**
     * 通过判断状态码统一处理响应，根据情况修改
     * 同时也可以通过HTTP状态码判断请求结果
     */
    (response) => {
        const res = response.data;
        // 如果状态码不是20000则认为有错误
        if (res.Code !== 200) {
            // 50008: 非法令牌; 50012: 其他客户端已登入; 50014: 令牌过期;
            if (res.Code === 1001) {
                return getUserToken({id:user.id}).then(res => {
                    // 刷新token成功，将最新的token更新到header中，同时保存在localStorage中
                    const { token } = res.Data;
                    localStorage.setItem('user_token',token);
                    // 获取当前失败的请求
                    const config = response.config
                    // 重置一下配置
                    config.headers['X-Token'] = token
                    config.baseURL = '/api/' // url已经带上了/api，避免出现/api/api的情况
                    // 重试当前请求并返回promise
                    return service(config)
                }).catch(res => {
                    console.error('refreshtoken error =>', res)
                    //刷新token失败，神仙也救不了了，跳转到首页重新登录吧
                    localStorage.removeItem('user_token');
                    localStorage.removeItem('user');
                    directLogin();
                })
            }else{
                Toast({
                    message: res.Msg || "Error",
                    duration: 1000,
                });
            }
            return Promise.reject(new Error(res.Msg || "Error"));
        } else {
            return res;
        }
    },
    (error) => {
        console.log("err" + error); // for debug
        Toast({
            type: 'error',
            message: error.message,
            duration: 1000,
        });
        return Promise.reject(error);
    }
);
/**
 * 登录跳转
 */
function directLogin() {
    router.push({
        path: '/login',
        query: {
            redirectUrl: window.location.href.split('#')[1] || ''
        }
    })
}

export default service;